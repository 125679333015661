import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';

const Hero = ({ onScrollToFeatures }) => {
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    // Start the sequence after 1 second
    const timer = setTimeout(() => {
      setCurrentStep(1); // Show video
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleVideoEnd = () => {
    // Add 1 second delay before starting text animations
    setTimeout(() => {
      setCurrentStep(2);
    }, 1000);
  };

  useEffect(() => {
    if (currentStep >= 2 && currentStep <= 4) {
      const timer = setTimeout(() => {
        setCurrentStep(prev => prev + 1);
      }, 2500); // Increased to allow for exit animation

      return () => clearTimeout(timer);
    }
  }, [currentStep]);

  const renderContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, x: '-100%' }}
            transition={{ duration: 0.5 }}
            className="max-w-lg mx-auto mt-16 scale-110"
          >
            <video
              autoPlay
              muted
              playsInline
              preload="auto"
              onEnded={handleVideoEnd}
              className="w-full h-auto"
              style={{ pointerEvents: 'none' }}
            >
              <source src="/videos/performance_local.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </motion.div>
        );
      case 2:
        return (
          <motion.div 
            className="max-w-lg mx-auto mt-16 relative overflow-hidden"
            key="realtime-container"
          >
            <motion.h2
              key="realtime"
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold flex flex-col"
            >
              <span>This was in <span className="text-accent-purple">realtime</span>!</span>
              <span><span className="text-accent-purple">nanosecond</span> performance.</span>
            </motion.h2>
          </motion.div>
        );
      case 3:
        return (
          <motion.div 
            className="max-w-lg mx-auto mt-16 relative overflow-hidden"
            key="million-container"
          >
            <motion.h2
              key="million"
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold flex flex-col"
            >
              <span>1 <span className="text-accent-purple">million</span> records.</span>
              <span>3 million <span className="text-accent-purple">operations</span>.</span>
            </motion.h2>
          </motion.div>
        );
      case 4:
        return (
          <motion.div 
            className="max-w-lg mx-auto mt-16 relative overflow-hidden"
            key="node-container"
          >
            <motion.h2
              key="node"
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '-100%' }}
              transition={{ duration: 0.5 }}
              className="text-4xl font-bold flex flex-col"
            >
              <span>Tested on a <span className="text-accent-purple">single</span> node!</span>
              <span>Inside a <span className="text-accent-purple">container</span>!</span>
            </motion.h2>
          </motion.div>
        );
      case 5:
        return (
          <div>
          <motion.div
            key="illustration"
            initial={{ opacity: 0, y: 40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="max-w-lg mx-auto mt-16"
          >
            <img
              src="/images/hpkv-illustration.svg"
              alt="HPKV Illustration"
              className="w-full h-auto opacity-90"
            />
          </motion.div>
          <br/>
          <br/>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
            className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4"
          >
            <Link
              to="/about"
              className="inline-flex items-center px-8 py-3 text-lg font-semibold text-white bg-accent-purple hover:bg-accent-blue transition-colors duration-150 rounded-lg"
            >
              Learn more
              <span className="ml-2">→</span>
            </Link>
            <Link
              to="/docs"
              className="inline-flex items-center px-8 py-3 text-lg font-semibold text-heading-text bg-secondary hover:bg-secondary/80 transition-colors duration-150 rounded-lg"
            >
              Read the docs
            </Link>
          </motion.div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative min-h-screen bg-hero-gradient overflow-hidden">
      {/* Background glow effects */}
      <div className="absolute inset-0 bg-background/80 mix-blend-overlay" />
      <div className="absolute w-1/3 h-1/3 bg-accent-purple/30 rounded-full blur-3xl top-0 -left-10 animate-pulse" />
      <div className="absolute w-1/3 h-1/3 bg-accent-blue/20 rounded-full blur-3xl top-1/4 -right-10" />
      
      {/* Content */}
      <div className="relative container mx-auto px-4 pt-32 pb-20 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="max-w-3xl mx-auto"
        >
          {/* Beta badge */}
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="inline-flex items-center px-4 py-1 mb-6 rounded-full bg-accent-purple/10 border border-accent-purple/20"
          >
            <span className="text-sm text-accent-purple">HPKV free tier now available</span>
            <span className="ml-1 text-accent-purple">→</span>
          </motion.div>

          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="text-5xl md:text-6xl font-bold text-heading-text mb-6"
          >
            Ultra low-latency<br/>KV Store
          </motion.h1>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
            className="text-xl text-text mb-10 max-w-2xl mx-auto"
          >
            The only consistent <span className="text-accent-purple">nanosecond level</span> KV store on the market!<br/>High performance, low-latency, <span className="text-accent-purple">disk persistence</span> and low operational cost.
          </motion.p>

        </motion.div>

        {/* Animated content area */}
        <AnimatePresence mode="wait" initial={false}>
          {renderContent()}
        </AnimatePresence>

      </div>

      {/* Scroll indicator */}
      <motion.button
        onClick={onScrollToFeatures}
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 1, duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2 cursor-pointer text-text/30 hover:text-text/50 transition-colors"
      >
        <svg 
          className="w-8 h-8" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M19 14l-7 7m0 0l-7-7m7 7V3" 
          />
        </svg>
      </motion.button>
    </div>
  );
};

export default Hero;
