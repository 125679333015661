import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';

const tiers = [
  {
    id: 1,
    name: 'Free',
    price: { monthly: 0, yearly: 0 },
    description: 'Whether it\'s for fun or a side project, you can get started with us for free.',
    features: {
      limits: ['100 requests/second', '1000 records', 'Unlimited Operations', '128 bytes Key', '1 KB Value'],
      server_type: ['Shared Server', 'Daily Backups'],
      high_availability: ['No High Availability'],
      features: ['REST API', 'gRPC API', 'Latency as low as 5 ms ¹', 'No RIOC (Remote IOctl Call)', 'No Nanosecond latency'],
      support: ['No Support']
    }
  },
  {
    id: 2,
    name: 'Pro',
    price: { monthly: 15, yearly: 12 },
    description: 'For small businesses and startups.',
    features: {
      limits: ['1,000 requests/second', '100,000 records', 'Unlimited Operations', '256 bytes Key', '10 KB Value'],
      server_type: ['Shared Server', 'Daily Backups'],
      high_availability: ['No High Availability'],
      features: ['REST API', 'gRPC API', 'Latency as low as 1 ms ¹', 'No RIOC (Remote IOctl Call)', 'No Nanosecond latency'],
      support: ['Premium Support']
    }
  },
  {
    id: 3,
    name: 'Business',
    price: { monthly: 99, yearly: 79 },
    description: 'For medium-sized businesses and startups.',
    features: {
      limits: ['10,000 requests/second', '10M records or 100 GB of data ⁴', 'Unlimited Operations', '500 bytes Key ⁴', '100 KB Value ⁴'],
      server_type: ['Dedicated Server', 'Data Recovery Guarantee'],
      high_availability: ['Multi-region replication & High Availability (99.99% uptime)'],
      features: ['REST API', 'gRPC API', 'Latency as low as 250 µs ¹', 'No RIOC (Remote IOctl Call)', 'No Nanosecond latency'],
      support: ['Premium Support']
    }
  },
  {
    id: 4,
    name: 'Enterprise',
    price: { monthly: 0, yearly: 0 },
    description: 'For large enterprises.',
    features: {
      limits: ['Up to 3,000,000 requests/second (single node)', '1B records or 10TB of data ⁴','Unlimited Operations', '1000 bytes Key ⁴', '10 MB Value ⁴'],
      server_type: ['Dedicated Server', 'Data Recovery Guarantee'],
      high_availability: ['Multi-region replication & High Availability (99.99% uptime) or Nanosecond Latency'],
      features: ['REST API', 'gRPC API', 'Latency as low as 250 ns ²', 'RIOC (Remote IOctl Call) ³', 'Nanosecond latency'],
      support: ['24/7 Premium Support']
    }
  },
];

const FeatureSection = ({ title, features }) => (
  <div className="mb-6">
    <h4 className="text-sm font-semibold text-heading-text mb-2">{title}</h4>
    <ul className="space-y-2">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center text-text">
          {feature.includes('No') ? (
            <XMarkIcon className="h-4 w-4 text-red-500 mr-2 flex-shrink-0" />
          ) : (
            <CheckIcon className="h-4 w-4 text-accent-purple mr-2 flex-shrink-0" />
          )}
          <span className="text-sm">{feature}</span>
        </li>
      ))}
    </ul>
  </div>
);

const Pricing = () => {
  const [isYearly, setIsYearly] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        setIsLoggedIn(true);
        try {
          const response = await fetch('/api/subscriptions', {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (response.ok) {
            const data = await response.json();
            setCurrentPlanId(data.id);
          }
        } catch (error) {
          console.error('Failed to fetch subscription:', error);
        }
      } else {
        setIsLoggedIn(false);
        setCurrentPlanId(null);
      }
    };

    checkLoginStatus();
  }, []);

  const handleGetStarted = (tier) => {
    if (tier.name === 'Free' && !isLoggedIn) {
      navigate('/signup');
    } else if (tier.name === 'Enterprise') {
      // Add contact form logic here
      window.location.href = 'mailto:sales@hpkv.io';
    }
  };

  return (
    <section className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-sm font-semibold text-accent-purple mb-4">Pricing plans</p>
          <h2 className="text-3xl md:text-4xl font-bold text-heading-text mb-6">
            Flexible plans and features
          </h2>
          <p className="text-xl text-text mb-8">
            Choose the perfect plan for your needs. Always know what you'll pay.
          </p>

          {/* Billing toggle */}
          <div className="flex items-center justify-center space-x-3">
            <span className={`text-sm ${!isYearly ? 'text-heading-text' : 'text-text'}`}>Monthly</span>
            <button
              onClick={() => setIsYearly(!isYearly)}
              className="relative inline-flex h-6 w-11 items-center rounded-full bg-accent-purple/20"
            >
              <span className="sr-only">Enable yearly billing</span>
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-accent-purple transition ${
                  isYearly ? 'translate-x-6' : 'translate-x-1'
                }`}
              />
            </button>
            <span className={`text-sm ${isYearly ? 'text-heading-text' : 'text-text'}`}>
              Yearly <span className="text-accent-purple">(-20%)</span>
            </span>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          {tiers.map((tier, index) => (
            <motion.div
              key={tier.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="relative bg-component-bg rounded-2xl p-8 border border-gray-800 flex flex-col"
            >
              {currentPlanId === tier.id && (
                <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                  <span className="px-3 py-1 bg-accent-purple text-white text-sm rounded-full">
                    Your Current Plan
                  </span>
                </div>
              )}
              <div className="mb-6">
                <h3 className="text-xl font-semibold text-heading-text mb-2">{tier.name}</h3>
                <div className="flex items-baseline mb-2">
                  {tier.name === 'Enterprise' ? (
                    <span className="text-2xl font-medium text-text-secondary">Contact Us</span>
                  ) : (
                    <>
                      <span className="text-4xl font-bold text-heading-text">
                        €{isYearly ? tier.price.yearly : tier.price.monthly}
                      </span>
                      <span className="text-text ml-1">/mo</span>
                    </>
                  )}
                </div>
                <p className="text-text">{tier.description}</p>
              </div>

              <div className="flex-grow">
                <FeatureSection title="Limitations" features={tier.features.limits} />
                <FeatureSection title="Server Type" features={tier.features.server_type} />
                <FeatureSection title="High Availability" features={tier.features.high_availability} />
                <FeatureSection title="Features" features={tier.features.features} />
                <FeatureSection title="Support" features={tier.features.support} />
              </div>

              {currentPlanId === tier.id ? (
                <button 
                  disabled
                  className="w-full py-2 px-4 rounded-lg bg-gray-700 text-gray-400 cursor-not-allowed font-semibold"
                >
                  Current Plan
                </button>
              ) : tier.name === 'Free' ? (
                <button 
                  onClick={() => handleGetStarted(tier)}
                  className="w-full py-2 px-4 rounded-lg bg-accent-purple hover:bg-accent-blue transition-colors duration-200 text-white font-semibold"
                >
                  Get Started
                </button>
              ) : tier.name === 'Enterprise' ? (
                <button 
                  onClick={() => handleGetStarted(tier)}
                  className="w-full py-2 px-4 rounded-lg bg-accent-purple hover:bg-accent-blue transition-colors duration-200 text-white font-semibold"
                >
                  Contact Us
                </button>
              ) : (
                <button className="w-full py-2 px-4 rounded-lg bg-accent-purple hover:bg-accent-blue transition-colors duration-200 text-white font-semibold">
                  Coming Soon (Notify Me)
                </button>
              )}
            </motion.div>
          ))}
        </div>
        
        <div className="mt-12 text-xs text-text-secondary text-left max-w-7xl mx-auto px-4">
          ¹ The stated latency represents server-side processing time. Actual end-to-end response times may vary depending on network conditions and geographical distance to our servers.
          <br />
          ² Nanosecond latency is achievable when the client is running on the same machine as the HPKV Enterprise.
          <br />
          ³ RIOC (Remote IOctl Call) is a lightweight RPC protocol that allows you to call HPKV Enterprise from your own application, within your private network.
          <br />
          ⁴ This value can be increased by contacting us.
        </div>
      </div>
    </section>
  );
};

export default Pricing;
