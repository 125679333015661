import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const GrpcApi = () => (
  <div className="space-y-6">
    <h2 className="text-2xl font-bold mb-4">gRPC API</h2>
    <p>
      The HPKV gRPC API provides a high-performance, low-latency interface for interacting with the key-value store. It's designed for scenarios where maximum performance and efficiency are crucial.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Service Definition</h3>
    <p>
      The gRPC service is defined using Protocol Buffers. Here's the service definition:
    </p>
    <SyntaxHighlighter language="protobuf" style={vscDarkPlus}>
{`syntax = "proto3";

package hpkv;

service HPKVService {
  rpc InsertRecord(InsertRecordRequest) returns (InsertRecordResponse);
  rpc GetRecord(GetRecordRequest) returns (GetRecordResponse);
  rpc DeleteRecord(DeleteRecordRequest) returns (DeleteRecordResponse);
  rpc GetStats(GetStatsRequest) returns (GetStatsResponse);
}

message InsertRecordRequest {
  string key = 1;
  string value = 2;
  bool partial_update = 3;
}

message InsertRecordResponse {
  bool success = 1;
  string message = 2;
}

message GetRecordRequest {
  string key = 1;
}

message GetRecordResponse {
  string key = 1;
  string value = 2;
}

message DeleteRecordRequest {
  string key = 1;
}

message DeleteRecordResponse {
  bool success = 1;
  string message = 2;
}

message GetStatsRequest {}

message GetStatsResponse {
  map<string, string> stats = 1;
}`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">Authentication</h3>
    <p>
      All gRPC calls require an API key to be included in the metadata with the key <code className="bg-component-bg text-text p-1 rounded">x-api-key</code>.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Rate Limiting</h3>
    <p>
      gRPC calls are subject to rate limiting based on your plan. If you exceed the rate limit, you'll receive a RESOURCE_EXHAUSTED error.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Methods</h3>

    <h4 className="text-lg font-bold mt-4 mb-2">1. InsertRecord</h4>
    <p><strong>Description:</strong> Inserts a new record or updates an existing one.</p>
    <p><strong>Request:</strong> InsertRecordRequest</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">key</code> (string, required): The key for the record. Max length: 512 characters.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">value</code> (string, required): The value for the record. Max length: 100KB.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">partial_update</code> (boolean, optional): If true, performs a partial update on an existing record. Default: false.</li>
    </ul>
    <p><strong>Response:</strong> InsertRecordResponse</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">success</code> (boolean): Indicates if the operation was successful.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">message</code> (string): A message describing the result of the operation.</li>
    </ul>

    <h4 className="text-lg font-bold mt-4 mb-2">2. GetRecord</h4>
    <p><strong>Description:</strong> Retrieves a record by its key.</p>
    <p><strong>Request:</strong> GetRecordRequest</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">key</code> (string, required): The key of the record to retrieve.</li>
    </ul>
    <p><strong>Response:</strong> GetRecordResponse</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">key</code> (string): The key of the retrieved record.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">value</code> (string): The value of the retrieved record.</li>
    </ul>

    <h4 className="text-lg font-bold mt-4 mb-2">3. DeleteRecord</h4>
    <p><strong>Description:</strong> Deletes a record by its key.</p>
    <p><strong>Request:</strong> DeleteRecordRequest</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">key</code> (string, required): The key of the record to delete.</li>
    </ul>
    <p><strong>Response:</strong> DeleteRecordResponse</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">success</code> (boolean): Indicates if the operation was successful.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">message</code> (string): A message describing the result of the operation.</li>
    </ul>

    <h4 className="text-lg font-bold mt-4 mb-2">4. GetStats</h4>
    <p><strong>Description:</strong> Retrieves usage statistics for your account.</p>
    <p><strong>Request:</strong> GetStatsRequest (empty)</p>
    <p><strong>Response:</strong> GetStatsResponse</p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">stats</code> (map&lt;string, string&gt;): A map of statistic names to their values.</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Error Handling</h3>
    <p>
      gRPC errors are returned as status codes with error messages. Common error codes include:
    </p>
    <ul className="list-disc pl-6">
      <li>INVALID_ARGUMENT (3): Invalid input</li>
      <li>NOT_FOUND (5): Record not found</li>
      <li>ALREADY_EXISTS (6): Record already exists (for non-partial inserts)</li>
      <li>PERMISSION_DENIED (7): Invalid API key</li>
      <li>RESOURCE_EXHAUSTED (8): Rate limit exceeded</li>
      <li>INTERNAL (13): Server error</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Limitations</h3>
    <ul className="list-disc pl-6">
      <li>Maximum key size: 512 characters</li>
      <li>Maximum value size: 100KB</li>
      <li>Rate limits vary by plan. Check your plan details for specific limits.</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Example Usage</h3>
    <p>Here's an example of how to use the gRPC API with Python:</p>
    <SyntaxHighlighter language="python" style={vscDarkPlus}>
{`import grpc
from hpkv_pb2 import *
from hpkv_pb2_grpc import HPKVServiceStub

API_KEY = "your_api_key_here"
channel = grpc.secure_channel("api.hpkv.io:443", grpc.ssl_channel_credentials())
stub = HPKVServiceStub(channel)

# Metadata for authentication
metadata = [('x-api-key', API_KEY)]

# Insert a record
insert_request = InsertRecordRequest(key="example_key", value="example_value")
insert_response = stub.InsertRecord(insert_request, metadata=metadata)
print(f"Insert response: {insert_response.success}, {insert_response.message}")

# Get a record
get_request = GetRecordRequest(key="example_key")
get_response = stub.GetRecord(get_request, metadata=metadata)
print(f"Get response: {get_response.key} = {get_response.value}")

# Delete a record
delete_request = DeleteRecordRequest(key="example_key")
delete_response = stub.DeleteRecord(delete_request, metadata=metadata)
print(f"Delete response: {delete_response.success}, {delete_response.message}")

# Get statistics
stats_response = stub.GetStats(GetStatsRequest(), metadata=metadata)
print(f"Stats: {stats_response.stats}")

channel.close()`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">When to Use gRPC API</h3>
    <p>
      The gRPC API is ideal for:
    </p>
    <ul className="list-disc pl-6">
      <li>High-performance, low-latency applications</li>
      <li>Microservices architectures</li>
      <li>Scenarios where you need to handle a large number of requests per second</li>
      <li>When working with languages that have good gRPC support (e.g., Go, Java, Python)</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Strengths of gRPC API</h3>
    <ul className="list-disc pl-6">
      <li>High performance due to binary serialization and HTTP/2</li>
      <li>Efficient for both small and large data transfers</li>
      <li>Strong typing with Protocol Buffers</li>
      <li>Built-in support for streaming</li>
      <li>Bi-directional communication</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Weaknesses of gRPC API</h3>
    <ul className="list-disc pl-6">
      <li>Steeper learning curve compared to REST</li>
      <li>Less human-readable (binary format)</li>
      <li>Limited browser support</li>
      <li>Requires more setup and tooling</li>
    </ul>
  </div>
);

export default GrpcApi;
