import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    checkLoginStatus();
    window.addEventListener('storage', checkLoginStatus);
    window.addEventListener('auth-change', checkLoginStatus);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('storage', checkLoginStatus);
      window.removeEventListener('auth-change', checkLoginStatus);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event('auth-change'));
    window.location.href = '/';
  };

  const handleNavigation = () => {
    setIsOpen(false);
  };

  return (
    <header className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-background/95 backdrop-blur-sm shadow-lg' : 'bg-transparent'}`}>
      <nav className="px-6 py-4">
        <div className="flex justify-between items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Link to="/" onClick={handleNavigation}>
              <img 
                src="/images/hpkv-illustration.svg" 
                alt="HPKV Logo" 
                className="h-8 w-auto" 
              />
            </Link>
          </motion.div>
          <div className="hidden md:flex items-center space-x-1">
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              className="flex items-center space-x-1"
            >
              <Link to="/about" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">About</Link>
              <Link to="/docs" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">Docs</Link>
              <Link to="/blog" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">Blog</Link>
              <Link to="/pricing" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">Pricing</Link>
              <Link to="/" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">Home</Link>
              {isLoggedIn ? (
                <>
                  <Link to="/dashboard" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">Dashboard</Link>
                  <button 
                    onClick={handleLogout}
                    className="ml-2 px-4 py-2 text-sm text-white bg-accent-purple hover:bg-accent-blue transition-colors rounded-lg"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <Link to="/login" onClick={handleNavigation} className="px-4 py-2 text-sm text-text hover:text-heading-text transition-colors">Login</Link>
                  <Link 
                    to="/signup"
                    onClick={handleNavigation}
                    className="ml-2 px-4 py-2 text-sm text-white bg-accent-purple hover:bg-accent-blue transition-colors rounded-lg"
                  >
                    Sign Up
                  </Link>
                </>
              )}
            </motion.div>
          </div>
          <div className="md:hidden">
            <button 
              onClick={() => setIsOpen(!isOpen)}
              className="text-text hover:text-heading-text transition-colors"
            >
              <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                {isOpen ? (
                  <path fillRule="evenodd" clipRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" />
                ) : (
                  <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
                )}
              </svg>
            </button>
          </div>
        </div>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden mt-4 bg-component-bg rounded-lg shadow-lg p-4"
          >
            <Link to="/docs" onClick={handleNavigation} className="block py-2 text-text hover:text-heading-text transition-colors">Docs</Link>
            <Link to="/blog" onClick={handleNavigation} className="block py-2 text-text hover:text-heading-text transition-colors">Blog</Link>
            <Link to="/about" onClick={handleNavigation} className="block py-2 text-text hover:text-heading-text transition-colors">About</Link>
            <Link to="/pricing" onClick={handleNavigation} className="block py-2 text-text hover:text-heading-text transition-colors">Pricing</Link>
            {isLoggedIn ? (
              <>
                <Link to="/dashboard" onClick={handleNavigation} className="block py-2 text-text hover:text-heading-text transition-colors">Dashboard</Link>
                <button 
                  onClick={handleLogout}
                  className="block w-full mt-2 px-4 py-2 text-white bg-accent-purple hover:bg-accent-blue transition-colors rounded-lg text-left"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" onClick={handleNavigation} className="block py-2 text-text hover:text-heading-text transition-colors">Login</Link>
                <Link 
                  to="/signup"
                  onClick={handleNavigation}
                  className="block mt-2 px-4 py-2 text-white bg-accent-purple hover:bg-accent-blue transition-colors rounded-lg"
                >
                  Sign Up
                </Link>
              </>
            )}
          </motion.div>
        )}
      </nav>
    </header>
  );
};

export default Header;
