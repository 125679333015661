import React, { useEffect, useRef } from 'react';
import mermaid from 'mermaid';

const MermaidDiagram = ({ chart }) => {
  const ref = useRef(null);

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: true,
      securityLevel: 'loose',
      theme: 'dark'
    });

    if (ref.current) {
      ref.current.innerHTML = '';
      mermaid.render('mermaid-diagram', chart).then(({ svg }) => {
        ref.current.innerHTML = svg;
        
        // Handle node labels
        const nodeLabels = ref.current.querySelectorAll('.node text');
        nodeLabels.forEach(text => {
          // Make text visible
          text.style.fontSize = '12px';
          text.style.fill = 'currentColor';
          text.style.visibility = 'visible';
          text.style.opacity = '1';
        });

        // Handle edge labels
        const edgeLabels = ref.current.querySelectorAll('.edgeLabel text');
        edgeLabels.forEach(text => {
          text.style.fontSize = '11px';
          text.style.fill = 'currentColor';
        });

        // Ensure all text elements are visible
        const allText = ref.current.querySelectorAll('text');
        allText.forEach(text => {
          text.style.visibility = 'visible';
          text.style.opacity = '1';
          text.style.display = 'block';
        });

        // Ensure all foreignObjects are properly sized
        const foreignObjects = ref.current.querySelectorAll('foreignObject');
        foreignObjects.forEach(fo => {
          fo.style.overflow = 'visible';
          fo.style.width = 'auto';
          fo.style.height = 'auto';
          
          const div = fo.querySelector('div');
          if (div) {
            div.style.width = 'auto';
            div.style.height = 'auto';
            div.style.whiteSpace = 'nowrap';
            div.style.overflow = 'visible';
            div.style.visibility = 'visible';
            div.style.opacity = '1';
            div.style.display = 'block';
          }
        });
      });
    }
  }, [chart]);

  return (
    <div 
      ref={ref} 
      className="mermaid-diagram" 
      style={{ 
        width: '100%', 
        height: '100%'
      }} 
    />
  );
};

export default MermaidDiagram; 