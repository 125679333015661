import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState('');
  const [turnstileToken, setTurnstileToken] = useState('');
  const turnstileRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Reset and render Turnstile when component mounts
    if (window.turnstile && turnstileRef.current) {
      window.turnstile.render(turnstileRef.current, {
        sitekey: "0x4AAAAAAA48xdPOI1jvNGiS",
        theme: "dark",
        callback: (token) => setTurnstileToken(token),
      });
    }

    // Cleanup function to remove the widget when component unmounts
    return () => {
      if (window.turnstile) {
        window.turnstile.remove();
      }
    };
  }, []); // Empty dependency array means this runs once when component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!turnstileToken) {
      setError('Please complete the CAPTCHA challenge');
      return;
    }

    try {
      const response = await fetch('/api/auth/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, rememberMe, turnstileToken }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'Error logging in');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);
      window.dispatchEvent(new Event('auth-change'));
      onLogin(data.user);
      
      navigate('/dashboard');
    } catch (err) {
      setError(err.message);
      // Reset Turnstile on error
      if (window.turnstile) {
        window.turnstile.reset();
      }
    }
  };

  return (
    <div className="min-h-screen bg-background">
      <div className="hidden md:block absolute top-0 bottom-0 left-1/2 bg-gray-50" aria-hidden="true" />
      <div className="relative flex flex-col items-center justify-center min-h-screen">
        <div className="absolute w-1/3 h-1/3 bg-accent-purple/30 rounded-full blur-3xl -top-10 -left-10 animate-pulse" />
        <div className="absolute w-1/3 h-1/3 bg-accent-blue/20 rounded-full blur-3xl bottom-0 right-0" />

        <div className="w-full max-w-md space-y-8 px-4">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl font-bold tracking-tight text-heading-text"
            >
              Welcome back
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="mt-3 text-base text-text"
            >
              Enter your credentials to access your account
            </motion.p>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-component-bg p-8 rounded-xl shadow-lg border border-gray-800"
          >
            {error && (
              <div className="mb-4 p-4 rounded-lg bg-red-500/10 border border-red-500/20">
                <p className="text-sm text-red-500">{error}</p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-text mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-text mb-2">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="w-full px-4 py-3 bg-background border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>

              <div className="flex items-center justify-between">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox rounded border-gray-700 text-accent-purple focus:ring-accent-purple bg-background"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                  />
                  <span className="ml-2 text-sm text-text">Keep me signed in</span>
                </label>
                <Link to="/forgot-password" className="text-sm font-medium text-accent-purple hover:text-accent-blue">
                  Forgot password?
                </Link>
              </div>

              <div className="flex justify-center">
                <div ref={turnstileRef}></div>
              </div>

              <button
                type="submit"
                className="w-full bg-accent-purple text-white font-semibold py-3 px-4 rounded-lg hover:bg-accent-blue transition-colors duration-200"
              >
                Sign in
              </button>
            </form>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Login;
