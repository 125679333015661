import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MermaidDiagram from '../components/MermaidDiagram';

const About = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [key, setKey] = useState(0);

  const architectureDiagram = `
    %%{
      init: {
        'theme': 'dark',
        'themeVariables': {
          'fontFamily': 'arial',
          'lineWidth': '2px',
          'fontSize': '16px',
          'messageFontSize': '13px',
          'nodeTextPadding': 16,
          'textAlignment': 'center',
          'labelBoxWidth': 160,
          'labelBoxHeight': 40
        },
        'flowchart': {
          'htmlLabels': true,
          'curve': 'basis',
          'diagramPadding': 12,
          'nodeSpacing': 60,
          'padding': 16,
          'useMaxWidth': true,
          'width': 160,
          'height': 40,
          'boxMargin': 8
        }
      }
    }%%
    graph LR
      classDef coreNode fill:#4C51BF,color:#fff,stroke:#805AD5,stroke-width:3px
      classDef engineNode fill:#2D3748,color:#fff,stroke:#4A5568,stroke-width:2px
      classDef storageNode fill:#1A202C,color:#fff,stroke:#2D3748,stroke-width:2px
      classDef readPath fill:#22543D,color:#fff,stroke:#276749,stroke-width:3px
      classDef writePath fill:#742A2A,color:#fff,stroke:#9B2C2C,stroke-width:3px

      subgraph MM["Memory Architecture"]
        CE[Clock-Based Cache]
        RC[Record Cache]
        WB[Write Buffer]
        MP[Memory Pool]
        PA[Predictive Allocator]
        CE -->|Optimize| RC
        RC -->|Fast Access| MP
        WB -->|Batch Write| MP
        PA -->|Smart Alloc| MP
      end

      subgraph CC["Concurrency Engine"]
        PC[Parallel Control]
        AO[Lock-Free Ops]
        WQ[Work Queue]
        AS[Adaptive Scheduler]
        PC -->|Coordinate| WQ
        AO -->|Zero Contention| WQ
        WQ -->|Process| WB
        AS -->|Optimize| WQ
      end

      subgraph SL["Storage Layer"]
        direction TB
        MD[Metadata Engine]
        SM[Space Allocator]
        DST[Storage Engine]
        FSM[Free Space Merger]
        MD -->|Manage| SM
        SM -->|Optimize| DST
        FSM -->|Zero-Frag| SM
      end

      subgraph DS["Data Structure"]
        RBT[Tree Index]
        FST[Space Manager]
        HT[Hash Index]
        PF[Predictive Fetch]
        RBT -->|Ordered Access| HT
        FST -->|Real-Time Track| SM
        HT -->|Fast Lookup| RBT
        PF -->|Prefetch| RC
      end

      %% Data flow
      WB -->|Optimized Write| DST
      RBT -->|Update Index| DST
      FST -->|Smart Alloc| SM
      RC -->|Cache Hit| RBT
      HT -->|Fast Lookup| RC
      DST -->|Cache Miss| RC
      WQ -->|Process| WB
      PA -->|Hint| PF
      FSM -->|Optimize| FST

      class RC,WB,MP,CE,PA coreNode
      class PC,AO,WQ,AS engineNode
      class RBT,HT,FST,PF engineNode
      class DST,SM,MD,FSM storageNode
      class WB,FST,DST writePath
      class RC,HT,RBT readPath

      linkStyle default stroke-width:2px
  `;

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsExpanded(false);
      setKey(prev => prev + 1);
    }
  };

  const handleClose = () => {
    setIsExpanded(false);
    setKey(prev => prev + 1);
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className="relative min-h-screen bg-background pt-24">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row gap-12">
          {/* Diagram column */}
          <div className="lg:w-1/2 order-2 lg:order-1">
            <div className="sticky top-24">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
                className={`relative rounded-2xl overflow-hidden shadow-2xl bg-component-bg p-2 cursor-zoom-in hover:scale-[1.02] transition-transform duration-200 ${isExpanded ? 'cursor-zoom-out' : ''}`}
                style={{ 
                  height: 'auto',//isExpanded ? 'auto' : '520px',
                  width: '100%'
                }}
                onClick={() => {
                  setIsExpanded(!isExpanded);
                  if (isExpanded) setKey(prev => prev + 1);
                }}
              >
                <div className="w-full h-full scale-[0.95] origin-center">
                  <MermaidDiagram key={key} chart={architectureDiagram} />
                </div>
              </motion.div>
            </div>
          </div>

          {/* Content column */}
          <div className="lg:w-1/2 order-1 lg:order-2">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
            >
              <h1 className="text-4xl md:text-5xl font-bold text-heading-text mb-6">
                The Story Behind HPKV
              </h1>

              <div className="space-y-8 text-lg">
                <p>
                  HPKV emerged from a simple yet powerful vision: to create a key-value store that 
                  delivers consistent nanosecond-level performance without compromises. Our journey 
                  began when we noticed a gap in the market for a truly high-performance, low-latency 
                  KV store that could handle modern application demands.
                </p>

                <p>
                  What sets HPKV apart is our innovative approach to KV store architecture. We've 
                  reimagined how data storage and retrieval should work at the fundamental level, 
                  pushing the boundaries of what's possible with modern hardware.
                </p>

                <p>
                  Our team consists of passionate engineers and researchers who have years 
                  of experience in the field. From kernel-level optimizations and novel algorithms 
                  to sophisticated caching mechanisms, every component has been carefully crafted 
                  to deliver exceptional performance.
                </p>

                <p>
                  But we're not just about raw performance. We believe that powerful technology 
                  should be accessible to everyone. That's why we've wrapped our sophisticated 
                  engine in a simple, intuitive REST API that any developer can start using 
                  in minutes.
                </p>

                <p>
                  Today, HPKV is aiming to help companies across various industries achieve unprecedented 
                  performance in their applications. Whether you're building a high-frequency 
                  trading platform or a real-time analytics system, HPKV provides the foundation 
                  you need for success.
                </p>

                <div className="pt-8">
                  <h2 className="text-2xl font-bold text-heading-text mb-4">
                    Our Mission
                  </h2>
                  <p>
                    To democratize access to high-performance data storage and empower developers 
                    to build faster, more responsive applications without compromising on reliability 
                    or ease of use.
                  </p>
                </div>

                <div className="pt-8">
                  <h2 className="text-2xl font-bold text-heading-text mb-4">
                    Looking Forward
                  </h2>
                  <p>
                    We're continuously pushing the boundaries of what's possible with data storage 
                    technology. Our roadmap includes exciting features like multi-region replication, 
                    advanced monitoring capabilities, and even faster performance optimizations.
                  </p>
                  <br/>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Expanded view overlay */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-80 z-50 overflow-auto overscroll-none"
            onClick={handleClose}
          >
            <div className="min-h-screen min-w-screen p-4 flex items-center justify-center">
              <motion.div
                initial={{ scale: 0.9 }}
                animate={{ scale: 1 }}
                exit={{ scale: 0.9 }}
                className="bg-component-bg rounded-2xl p-2 w-full max-w-[98vw] min-h-[98vh] relative touch-pan-x touch-pan-y"
                onClick={e => e.stopPropagation()}
              >
                <button
                  className="fixed top-4 right-4 text-gray-400 hover:text-white p-2 z-50 bg-black bg-opacity-50 rounded-full shadow-lg"
                  onClick={handleClose}
                >
                  <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
                <div className="w-full h-full overflow-auto">
                  <div className="min-w-fit">
                    <MermaidDiagram key={`expanded-${key}`} chart={architectureDiagram} />
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default About; 