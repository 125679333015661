import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const RestApi = () => (
  <div className="space-y-6">
    <h2 className="text-2xl font-bold mb-4">REST API</h2>
    <p>
      The HPKV REST API provides a simple and familiar interface for interacting with the key-value store. It's designed to be easy to use and integrate with existing systems.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Base URL</h3>
    <p>
      All API requests should be made to:
    </p>
    <SyntaxHighlighter language="bash" style={vscDarkPlus}>
      https://api.hpkv.io
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">Authentication</h3>
    <p>
      All API requests require an API key to be included in the <code className="bg-component-bg text-text p-1 rounded">x-api-key</code> header.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Rate Limiting</h3>
    <p>
      API requests are subject to rate limiting based on your plan. If you exceed the rate limit, you'll receive a 429 Too Many Requests response.
    </p>

    <h3 className="text-xl font-bold mt-6 mb-2">Endpoints</h3>

    <h4 className="text-lg font-bold mt-4 mb-2">1. Insert/Update Record</h4>
    <SyntaxHighlighter language="http" style={vscDarkPlus} className="rounded-md">
{`POST /record
Content-Type: application/json
x-api-key: YOUR_API_KEY

{
  "key": "example_key",
  "value": "example_value",
  "partialUpdate": false
}`}
    </SyntaxHighlighter>
    <p><strong>Description:</strong> Inserts a new record or updates an existing one.</p>
    <p><strong>Request Body:</strong></p>
    <ul className="list-disc pl-6">
      <li><code className="bg-component-bg text-text p-1 rounded">key</code> (string, required): The key for the record. Max length: 512 characters.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">value</code> (string, required): The value for the record. Max length: 100KB.</li>
      <li><code className="bg-component-bg text-text p-1 rounded">partialUpdate</code> (boolean, optional): If true, performs a partial update on an existing record. Default: false.</li>
    </ul>
    <p><strong>Response:</strong></p>
    <ul className="list-disc pl-6">
      <li>Status 200 OK if successful</li>
      <li>Status 400 Bad Request if the request is invalid</li>
      <li>Status 401 Unauthorized if the API key is invalid</li>
      <li>Status 429 Too Many Requests if rate limit is exceeded</li>
      <li>Status 500 Internal Server Error if there's a server-side issue</li>
    </ul>

    <h4 className="text-lg font-bold mt-4 mb-2">2. Get Record</h4>
    <SyntaxHighlighter language="http" style={vscDarkPlus}>
{`GET /record/:key
x-api-key: YOUR_API_KEY`}
    </SyntaxHighlighter>
    <p><strong>Description:</strong> Retrieves a record by its key.</p>
    <p><strong>Parameters:</strong></p>
    <ul className="list-disc pl-6">
      <li><code>key</code> (string, required): The key of the record to retrieve.</li>
    </ul>
    <p><strong>Response:</strong></p>
    <ul className="list-disc pl-6">
      <li>Status 200 OK if the record is found, with the record in the response body</li>
      <li>Status 404 Not Found if the record doesn't exist</li>
      <li>Status 401 Unauthorized if the API key is invalid</li>
      <li>Status 429 Too Many Requests if rate limit is exceeded</li>
      <li>Status 500 Internal Server Error if there's a server-side issue</li>
    </ul>

    <h4 className="text-lg font-bold mt-4 mb-2">3. Delete Record</h4>
    <SyntaxHighlighter language="http" style={vscDarkPlus}>
{`DELETE /record/:key
x-api-key: YOUR_API_KEY`}
    </SyntaxHighlighter>
    <p><strong>Description:</strong> Deletes a record by its key.</p>
    <p><strong>Parameters:</strong></p>
    <ul className="list-disc pl-6">
      <li><code>key</code> (string, required): The key of the record to delete.</li>
    </ul>
    <p><strong>Response:</strong></p>
    <ul className="list-disc pl-6">
      <li>Status 200 OK if the record is successfully deleted</li>
      <li>Status 404 Not Found if the record doesn't exist</li>
      <li>Status 401 Unauthorized if the API key is invalid</li>
      <li>Status 429 Too Many Requests if rate limit is exceeded</li>
      <li>Status 500 Internal Server Error if there's a server-side issue</li>
    </ul>

    <h4 className="text-lg font-bold mt-4 mb-2">4. Get Statistics</h4>
    <SyntaxHighlighter language="http" style={vscDarkPlus}>
{`GET /stats
x-api-key: YOUR_API_KEY`}
    </SyntaxHighlighter>
    <p><strong>Description:</strong> Retrieves usage statistics for your account.</p>
    <p><strong>Response:</strong></p>
    <ul className="list-disc pl-6">
      <li>Status 200 OK with statistics in the response body</li>
      <li>Status 401 Unauthorized if the API key is invalid</li>
      <li>Status 429 Too Many Requests if rate limit is exceeded</li>
      <li>Status 500 Internal Server Error if there's a server-side issue</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Error Handling</h3>
    <p>
      All error responses will include a JSON object with an <code>error</code> field describing the error. For example:
    </p>
    <SyntaxHighlighter language="json" style={vscDarkPlus}>
{`{
  "error": "Record not found"
}`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">Limitations</h3>
    <ul className="list-disc pl-6">
      <li>Maximum key size: 512 characters</li>
      <li>Maximum value size: 100KB</li>
      <li>Rate limits vary by plan. Check your plan details for specific limits.</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Example Usage</h3>
    <p>Here's an example of how to use the REST API with Python:</p>
    <SyntaxHighlighter language="python" style={vscDarkPlus}>
{`import requests

API_KEY = "your_api_key_here"
BASE_URL = "https://api.hpkv.io"

headers = {
    "Content-Type": "application/json",
    "x-api-key": API_KEY
}

# Insert a record
response = requests.post(f"{BASE_URL}/record", 
                         headers=headers, 
                         json={"key": "example_key", "value": "example_value"})
print(response.json())

# Get a record
response = requests.get(f"{BASE_URL}/record/example_key", headers=headers)
print(response.json())

# Delete a record
response = requests.delete(f"{BASE_URL}/record/example_key", headers=headers)
print(response.json())

# Get statistics
response = requests.get(f"{BASE_URL}/stats", headers=headers)
print(response.json())`}
    </SyntaxHighlighter>

    <h3 className="text-xl font-bold mt-6 mb-2">When to Use REST API</h3>
    <p>
      The REST API is ideal for:
    </p>
    <ul className="list-disc pl-6">
      <li>Web applications where HTTP is the primary protocol</li>
      <li>Scenarios where broad language support is needed</li>
      <li>Simple integrations where ease of use is prioritized over maximum performance</li>
      <li>When working with frameworks or libraries that have built-in support for RESTful APIs</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Strengths of REST API</h3>
    <ul className="list-disc pl-6">
      <li>Simplicity and familiarity for most developers</li>
      <li>Wide support across programming languages and frameworks</li>
      <li>Easy to test and debug using standard HTTP tools</li>
      <li>Stateless nature allows for easy scaling</li>
    </ul>

    <h3 className="text-xl font-bold mt-6 mb-2">Weaknesses of REST API</h3>
    <ul className="list-disc pl-6">
      <li>May have higher latency compared to gRPC, especially for many small requests</li>
      <li>Less efficient for large data transfers due to JSON overhead</li>
      <li>Lack of built-in streaming support</li>
    </ul>
  </div>
);

export default RestApi;
