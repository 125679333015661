import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, KeyIcon, ChartBarIcon, CreditCardIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import ApiKeys from './ApiKeys';
import Overview from './Overview';

const sidebarItems = [
  { name: 'Overview', icon: HomeIcon, path: '/dashboard' },
  { name: 'API Keys', icon: KeyIcon, path: '/dashboard/api-keys' },
  { name: 'Usage', icon: ChartBarIcon, path: '/dashboard/usage' },
  { name: 'Billing', icon: CreditCardIcon, path: '/dashboard/billing' },
  { name: 'Settings', icon: Cog6ToothIcon, path: '/dashboard/settings' },
];

const Dashboard = ({ user: initialUser, onLogout }) => {
  const [user, setUser] = useState(initialUser);
  const [error, setError] = useState('');
  const [regions, setRegions] = useState([]);
  const [apiKeys, setApiKeys] = useState([]);
  const [newApiKey, setNewApiKey] = useState('');
  const [showNewKey, setShowNewKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [operationsToday, setOperationsToday] = useState(0);
  const [skipAutoRefresh, setSkipAutoRefresh] = useState(false);
  const location = useLocation();
  const activeItem = sidebarItems.find(item => item.path === location.pathname)?.name || 'Overview';

  const fetchRegions = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        onLogout();
        return;
      }
      const response = await fetch('/api/regions', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch regions');
      const data = await response.json();
      setRegions(data);
    } catch (error) {
      setError('Failed to fetch regions');
    }
  }, [onLogout]);

  const fetchApiKeys = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/api-keys', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch API keys');
      const data = await response.json();
      setApiKeys(data.keys);
      setOperationsToday(data.operations_today);
    } catch (error) {
      setError('Failed to fetch API keys');
    }
  }, []);

  const fetchSubscription = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/subscriptions', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Failed to fetch subscription');
      const data = await response.json();
      setSubscription(data);
    } catch (error) {
      console.error('Subscription error:', error);
      setError('Failed to fetch subscription');
    }
  }, []);

  const fetchUserData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        onLogout();
        return;
      }

      const response = await fetch('/api/auth/user', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      setUser(data);
    } catch (error) {
      if (error.message.includes('401')) {
        onLogout();
      } else {
        setError('Failed to fetch user data');
      }
    }
  }, [onLogout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      onLogout();
      return;
    }
    // Always fetch fresh user data on mount
    fetchUserData();
  }, [fetchUserData, onLogout]);

  useEffect(() => {
    if (activeItem === 'API Keys' && !skipAutoRefresh) {
      setError('');
      fetchRegions();
      fetchApiKeys();
      fetchSubscription();
    } else if (activeItem === 'Overview') {
      fetchSubscription();
    } else if (activeItem !== 'API Keys') {
      setShowNewKey(false);
      setNewApiKey('');
      setSkipAutoRefresh(false);
      setError('');
    }
    
    // Clear API key display when switching away from API Keys tab
    if (activeItem !== 'API Keys') {
      setShowNewKey(false);
      setNewApiKey('');
    }
  }, [activeItem, skipAutoRefresh, fetchRegions, fetchApiKeys, fetchSubscription, setError, setShowNewKey, setNewApiKey, setSkipAutoRefresh]);

  const handleGenerateKey = async (selectedRegion, description) => {
    setLoading(true);
    setError('');
    try {
      const token = localStorage.getItem('token');
      const response = await fetch('/api/api-keys', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ regionId: selectedRegion, description }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate API key');
      }
      const data = await response.json();
      
      // Update the lists silently to get the new counts
      const [apiKeysResponse, subscriptionResponse] = await Promise.all([
        fetch('/api/api-keys', {
          headers: { 'Authorization': `Bearer ${token}` },
        }).then(res => res.json()),
        fetch('/api/subscriptions', {
          headers: { 'Authorization': `Bearer ${token}` },
        }).then(res => res.json())
      ]);
      
      // Update states with new data
      setApiKeys(apiKeysResponse.keys);
      setOperationsToday(apiKeysResponse.operations_today);
      setSubscription(subscriptionResponse);
      
      // Show the new key and prevent auto-refresh
      setNewApiKey(data.apiKey);
      setShowNewKey(true);
      setSkipAutoRefresh(true);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseNewKey = () => {
    setShowNewKey(false);
    setNewApiKey('');
    setSkipAutoRefresh(false);
    // Now fetch the updated data
    fetchApiKeys();
    fetchSubscription();
  };

  const handleRevokeKey = async (tenantId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/api-keys/${tenantId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) throw new Error('Failed to revoke API key');
      await fetchApiKeys();
    } catch (error) {
      setError('Failed to revoke API key');
    }
  };

  const handleUpdateDescription = async (tenantId, description) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`/api/api-keys/${tenantId}/description`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ description }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to update description');
      }
      await fetchApiKeys();
    } catch (error) {
      setError(error.message || 'Failed to update description');
    }
  };

  if (error && error !== 'Selected region is at capacity') {
    return <div className="text-red-500">{error}</div>;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  const renderContent = () => {
    switch (activeItem) {
      case 'Overview':
        return (
          <Overview
            user={user}
            subscription={subscription}
            onUpdateProfile={async (updates) => {
              try {
                const token = localStorage.getItem('token');
                const response = await fetch('/api/auth/update-profile', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  },
                  body: JSON.stringify({
                    name: updates.name,
                    companyName: updates.companyName
                  }),
                });

                if (!response.ok) {
                  const data = await response.json();
                  throw new Error(data.error || 'Failed to update profile');
                }

                await fetchUserData();
              } catch (error) {
                setError(error.message);
              }
            }}
          />
        );
      case 'API Keys':
        return (
          <ApiKeys
            regions={regions}
            apiKeys={apiKeys}
            subscription={subscription}
            operationsToday={operationsToday}
            onGenerateKey={handleGenerateKey}
            onRevokeKey={handleRevokeKey}
            onUpdateDescription={handleUpdateDescription}
            onCloseNewKey={handleCloseNewKey}
            error={error}
            loading={loading}
            showNewKey={showNewKey}
            newApiKey={newApiKey}
          />
        );
      case 'Usage':
        return <div className="bg-component-bg rounded-lg p-6 shadow-sm">Usage statistics coming soon</div>;
      case 'Billing':
        return <div className="bg-component-bg rounded-lg p-6 shadow-sm">Billing information coming soon</div>;
      case 'Settings':
        return <div className="bg-component-bg rounded-lg p-6 shadow-sm">Settings coming soon</div>;
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-background">
      {/* Sidebar - hidden on mobile, shown on md and up */}
      <div className="fixed inset-y-0 left-0 w-64 bg-component-bg hidden md:block">
        <nav className="mt-16 pt-4">
          {sidebarItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center px-6 py-3 text-sm font-medium ${
                activeItem === item.name
                  ? 'text-accent-purple bg-accent-purple/10'
                  : 'text-text hover:text-accent-purple hover:bg-accent-purple/5'
              }`}
            >
              <item.icon className="h-5 w-5 mr-3" />
              {item.name}
            </Link>
          ))}
        </nav>
      </div>

      {/* Mobile navigation */}
      <div className="md:hidden fixed bottom-0 inset-x-0 bg-component-bg border-t border-gray-700 z-50">
        <nav className="flex justify-around">
          {sidebarItems.map((item) => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex flex-col items-center px-4 py-3 text-xs font-medium ${
                activeItem === item.name
                  ? 'text-accent-purple'
                  : 'text-text hover:text-accent-purple'
              }`}
            >
              <item.icon className="h-5 w-5 mb-1" />
              {item.name}
            </Link>
          ))}
        </nav>
      </div>

      {/* Main content */}
      <div className="md:ml-64 p-4 md:p-8" style={{ marginTop: '64px', marginBottom: '64px' }}>
        <div className="mb-8">
          <h2 className="text-2xl font-semibold text-text">{activeItem}</h2>
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default Dashboard;
