import React, { useState } from 'react';
import { PencilIcon, XMarkIcon, CheckCircleIcon } from '@heroicons/react/24/outline';

const Overview = ({
  user,
  subscription,
  onUpdateProfile
}) => {
  const [editingField, setEditingField] = useState(null);
  const [editedName, setEditedName] = useState(user.name);
  const [editedCompanyName, setEditedCompanyName] = useState(user.company_name || '');

  const handleSave = async (field) => {
    const updates = {
      name: field === 'name' ? editedName : user.name,
      companyName: field === 'company_name' ? editedCompanyName : (user.company_name || '')
    };
    
    await onUpdateProfile(updates);
    setEditingField(null);
  };

  const handleCancel = (field) => {
    if (field === 'name') {
      setEditedName(user.name);
    } else if (field === 'company_name') {
      setEditedCompanyName(user.company_name || '');
    }
    setEditingField(null);
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-component-bg rounded-lg p-6 shadow-sm">
        <h3 className="text-xl font-semibold text-text mb-6">User Profile</h3>
        <div className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-text mb-2">Full name</label>
            <div className="flex items-center space-x-2">
              {editingField === 'name' ? (
                <div className="flex items-center space-x-2 flex-1">
                  <input
                    type="text"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                    className="flex-1 px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                  />
                  <button
                    onClick={() => handleSave('name')}
                    className="text-accent-purple hover:text-accent-blue"
                  >
                    <CheckCircleIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleCancel('name')}
                    className="text-text-secondary hover:text-text"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              ) : (
                <>
                  <p className="text-text flex-1">{user.name}</p>
                  <button
                    onClick={() => {
                      setEditedName(user.name);
                      setEditingField('name');
                    }}
                    className="text-text-secondary hover:text-text"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                </>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-text mb-2">Email address</label>
            <p className="text-text">{user.email}</p>
          </div>

          <div>
            <label className="block text-sm font-medium text-text mb-2">Company name</label>
            <div className="flex items-center space-x-2">
              {editingField === 'company_name' ? (
                <div className="flex items-center space-x-2 flex-1">
                  <input
                    type="text"
                    value={editedCompanyName}
                    onChange={(e) => setEditedCompanyName(e.target.value)}
                    className="flex-1 px-2 py-1 bg-background border border-gray-700 rounded focus:outline-none focus:ring-2 focus:ring-accent-purple focus:border-transparent text-text"
                  />
                  <button
                    onClick={() => handleSave('company_name')}
                    className="text-accent-purple hover:text-accent-blue"
                  >
                    <CheckCircleIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleCancel('company_name')}
                    className="text-text-secondary hover:text-text"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </button>
                </div>
              ) : (
                <>
                  <p className="text-text flex-1">{user.company_name || '-'}</p>
                  <button
                    onClick={() => {
                      setEditedCompanyName(user.company_name || '');
                      setEditingField('company_name');
                    }}
                    className="text-text-secondary hover:text-text"
                  >
                    <PencilIcon className="h-4 w-4" />
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {subscription && (
        <div className="bg-component-bg rounded-lg p-6 shadow-sm">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold text-text">Current Plan</h3>
            <span className="px-3 py-1 bg-accent-purple/10 text-accent-purple rounded-full text-sm">
              Active
            </span>
          </div>
          
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <h4 className="text-lg font-medium text-text">{subscription.name}</h4>
              <span className="text-lg font-medium text-text">
                {subscription.price_monthly > 0 ? `€${subscription.price_monthly}/month` : 'Free'}
              </span>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">API Keys</p>
                <p className="text-xl font-medium">{subscription.max_api_keys || 0}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max RPS</p>
                <p className="text-xl font-medium">{subscription.rateLimitMax ? (subscription.rateLimitMax / (subscription.rateLimitWindowMs / 1000)) : 0}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max Records</p>
                <p className="text-xl font-medium">{subscription.max_records ? subscription.max_records.toLocaleString() : 0}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max Key Size</p>
                <p className="text-xl font-medium">{subscription.maxKeySize ? `${subscription.maxKeySize} bytes` : '0 bytes'}</p>
              </div>
              <div className="p-3 bg-background rounded-lg border border-gray-700">
                <p className="text-xs text-text-secondary mb-1">Max Value Size</p>
                <p className="text-xl font-medium">{subscription.maxValueSize ? `${subscription.maxValueSize} bytes` : '0 bytes'}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview; 