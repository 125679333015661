import React, { useEffect, useRef } from 'react';
import Hero from '../components/Hero';
import Features from '../components/Features';
import Pricing from '../components/Pricing';

const Home = () => {
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === '#features' && featuresRef.current) {
        scrollToSection(featuresRef);
      } else if (hash === '#pricing' && pricingRef.current) {
        scrollToSection(pricingRef);
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange(); // Handle initial hash

    return () => window.removeEventListener('hashchange', handleHashChange);
  }, []);

  return (
    <div className="bg-background">
      <Hero onScrollToFeatures={() => scrollToSection(featuresRef)} />
      <div ref={featuresRef}>
        <Features />
      </div>
      <div ref={pricingRef}>
        <Pricing />
      </div>
    </div>
  );
};

export default Home;
