import React, { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import mermaid from 'mermaid';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

// Initialize mermaid with better dark mode support
mermaid.initialize({
  startOnLoad: true,
  theme: 'dark',
  themeVariables: {
    primaryColor: '#6366f1', // indigo-500
    primaryTextColor: '#f3f4f6', // gray-100
    primaryBorderColor: '#4f46e5', // indigo-600
    lineColor: '#9ca3af', // gray-400
    secondaryColor: '#4f46e5', // indigo-600
    tertiaryColor: '#312e81', // indigo-900
    textColor: '#f3f4f6', // gray-100
    mainBkg: '#1f2937', // gray-800
    nodeBorder: '#4f46e5', // indigo-600
    clusterBkg: '#1f2937', // gray-800
    titleColor: '#f3f4f6', // gray-100
    edgeLabelBackground: '#1f2937', // gray-800
    nodeTextColor: '#f3f4f6', // gray-100
  },
  securityLevel: 'loose',
  fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
});

const MermaidRenderer = ({ content }) => {
  useEffect(() => {
    mermaid.contentLoaded();
  }, [content]);

  return (
    <div className="mermaid my-8 p-4 rounded-lg bg-gray-800 shadow-lg">
      {content}
    </div>
  );
};

export default function MarkdownRenderer({ content }) {
  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          const language = match ? match[1] : '';

          if (language === 'mermaid') {
            return <MermaidRenderer content={String(children)} />;
          }

          return !inline ? (
            <SyntaxHighlighter
              style={oneDark}
              language={language}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
        // Add custom styling for other markdown elements
        h1: ({ node, children, ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4" {...props}>{children}</h1>,
        h2: ({ node, children, ...props }) => <h2 className="text-3xl font-bold mt-6 mb-3" {...props}>{children}</h2>,
        h3: ({ node, children, ...props }) => <h3 className="text-2xl font-bold mt-4 mb-2" {...props}>{children}</h3>,
        p: ({ node, children, ...props }) => <p className="text-gray-700 dark:text-gray-300 mb-4 leading-relaxed" {...props}>{children}</p>,
        ul: ({ node, children, ...props }) => <ul className="list-disc list-inside mb-4" {...props}>{children}</ul>,
        ol: ({ node, children, ...props }) => <ol className="list-decimal list-inside mb-4" {...props}>{children}</ol>,
        li: ({ node, children, ...props }) => <li className="mb-2" {...props}>{children}</li>,
        a: ({ node, children, href, ...props }) => (
          <a
            href={href}
            className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 underline"
            {...props}
          >
            {children}
          </a>
        ),
        blockquote: ({ node, children, ...props }) => (
          <blockquote
            className="border-l-4 border-gray-300 dark:border-gray-600 pl-4 my-4 italic"
            {...props}
          >
            {children}
          </blockquote>
        ),
        img: ({ node, src, alt = '', ...props }) => (
          <img
            src={src}
            alt={alt}
            className="max-w-full h-auto rounded-lg my-4"
            {...props}
            loading="lazy"
          />
        ),
        table: ({ node, children, ...props }) => (
          <div className="overflow-x-auto my-8">
            <table className="min-w-full divide-y divide-gray-700 dark:divide-gray-600" {...props}>
              {children}
            </table>
          </div>
        ),
        thead: ({ node, children, ...props }) => (
          <thead className="bg-gray-800 dark:bg-gray-700" {...props}>
            {children}
          </thead>
        ),
        tbody: ({ node, children, ...props }) => (
          <tbody className="divide-y divide-gray-700 dark:divide-gray-600 bg-gray-900 dark:bg-gray-800" {...props}>
            {children}
          </tbody>
        ),
        tr: ({ node, children, ...props }) => (
          <tr className="hover:bg-gray-800 dark:hover:bg-gray-700 transition-colors" {...props}>
            {children}
          </tr>
        ),
        th: ({ node, children, ...props }) => (
          <th className="px-6 py-3 text-left text-sm font-semibold text-gray-300 uppercase tracking-wider whitespace-nowrap" {...props}>
            {children}
          </th>
        ),
        td: ({ node, children, ...props }) => (
          <td className="px-6 py-4 text-sm text-gray-300 whitespace-nowrap" {...props}>
            {children}
          </td>
        ),
      }}
    >
      {content}
    </ReactMarkdown>
  );
} 