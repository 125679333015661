import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { BoltIcon, ChartBarIcon, CircleStackIcon, CodeBracketIcon, BuildingLibraryIcon, BanknotesIcon } from '@heroicons/react/24/outline';

const features = [
  {
    name: 'Exceptional Speed',
    description: 'Ultra-low latency performance with consistent sub-microsecond response times across all operations, even under heavy load.',
    icon: BoltIcon,
  },
  {
    name: 'Persistent Storage',
    description: 'Using advanced storage layouts, direct I/O, zero-copy, and intelligent scheduling, we push the boundaries of what is possible with persistent storage.',
    icon: CircleStackIcon,
  },
  {
    name: 'Low operational cost',
    description: 'Utilizing advanced techniques and novel algorithms, we provide 4-5x lower operational costs than leading in-memory KV stores.',
    icon: BanknotesIcon,
  },
  {
    name: 'Scalability',
    description: 'Designed to scale linearly with your data growth, without sacrificing performance.',
    icon: ChartBarIcon,
  },
  {
    name: 'Enterprise Ready',
    description: 'Catering to the needs of enterprise-grade applications with high availability, disaster recovery, and security features.',
    icon: BuildingLibraryIcon,
  },
  {
    name: 'Developer Friendly',
    description: 'Simple API, easy to use, and compatible with popular programming languages and frameworks.',
    icon: CodeBracketIcon,
  },
];

const FeatureCard = ({ feature }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setMousePosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <motion.div
      className="group relative overflow-hidden rounded-2xl bg-component-bg p-8 border border-gray-800 hover:border-accent-purple/50 transition-colors duration-300 min-h-[320px]"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Gradient overlay */}
      <div
        className="absolute inset-0 transition-opacity duration-300"
        style={{
          opacity: isHovered ? 1 : 0,
          background: isHovered
            ? `radial-gradient(800px circle at ${mousePosition.x}px ${mousePosition.y}px, rgba(93, 93, 255, 0.15), transparent 40%)`
            : '',
        }}
      />

      {/* Content */}
      <div className="relative z-10">
        <div className="flex flex-col h-full">
          <div className="mb-4">
            <div className="inline-flex items-center justify-center p-2 rounded-lg bg-accent-purple/10 group-hover:bg-accent-purple/20 transition-colors duration-300">
              <feature.icon className="h-6 w-6 text-accent-purple" />
            </div>
          </div>
          <h3 className="text-xl font-semibold text-heading-text group-hover:text-white transition-colors duration-300 mb-3">
            {feature.name}
          </h3>
          <p className="text-text group-hover:text-text/90 transition-colors duration-300 text-base">
            {feature.description}
          </p>
        </div>
      </div>

      {/* Subtle border gradient */}
      <div
        className="absolute inset-0 transition-opacity duration-300"
        style={{
          opacity: isHovered ? 1 : 0,
          background: 'linear-gradient(45deg, rgba(93, 93, 255, 0.15), transparent)',
          mask: 'linear-gradient(black, black) content-box, linear-gradient(black, black)',
          maskComposite: 'exclude',
        }}
      />
    </motion.div>
  );
};

const Features = () => {
  return (
    <section className="py-20 bg-background">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <p className="text-sm font-semibold text-accent-purple mb-4">Features</p>
          <h2 className="text-3xl md:text-4xl font-bold text-heading-text mb-6">
            Performance that speaks for itself
          </h2>
          <p className="text-xl text-text">
            <span className="text-accent-purple">2x</span> faster than leading in-memory KV Stores<br />
            <span className="text-accent-purple">5x</span> faster than popular disk-based solutions<br />
            Industry-leading P99 latencies of just <span className="text-accent-purple">5µs</span> for writes and <span className="text-accent-purple">2µs</span> for reads
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {features.map((feature) => (
            <FeatureCard key={feature.name} feature={feature} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
